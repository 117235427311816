<template>
  <div class="spa-status">
    <div
      :class="`status--${status
        .toLowerCase()
        .split(' ')
        .join('-')} ${statusClass}`"
    >
      {{ status }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    status: {
      type: String,
      required: true
    },
    statusClass: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.spa-status {
  @mixin status($status-name, $bg-color: grey, $color: white) {
    .status--#{$status-name} {
      background: $bg-color;
      color: $color;
      border-radius: 50em;
      text-align: center;
      padding: 6px 12px;
      width: fit-content;
    }
  }
  // LA
  @include status("la-pending", $color-warn);
  @include status("la-confirmed", lighten($color-secondary, 10));
  @include status("la-signed", $color-accent2);
  @include status("la-stamp-duty-paid", $color-success);
  @include status("without-la", #ddd, #888);
  // SPA
  @include status("spa-pending", $color-warn);
  @include status("spa-confirmed", lighten($color-secondary, 10));
  @include status("spa-signed", $color-accent2);
  @include status("spa-stamp-duty-paid", $color-success);
}
</style>

